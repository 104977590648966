export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [];

export const dictionary = {
		"/(web)": [~16,[4],[5]],
		"/(admin)/backend": [6,[2]],
		"/(admin)/dashboard": [7,[2]],
		"/(web)/debug": [18,[4],[5]],
		"/(web)/files": [19,[4],[5]],
		"/(web)/industries/[industry]": [~20,[4],[5]],
		"/(admin)/login": [8,[2]],
		"/(admin)/login/forgot-password": [9,[2]],
		"/(web)/page-not-found": [21,[4],[5]],
		"/(admin)/pay-invoice": [10,[2]],
		"/(web)/privacy": [22,[4],[5]],
		"/(web)/reviews": [23,[4],[5]],
		"/(admin)/review": [11,[2]],
		"/(admin)/setup": [12,[2,3]],
		"/(admin)/testing_homepage": [13,[2]],
		"/(web)/testing_john": [24,[4],[5]],
		"/(web)/testing_john/sketchup-referral": [25,[4],[5]],
		"/(admin)/testing_tutor": [14,[2]],
		"/(admin)/thank-you": [15,[2]],
		"/(web)/thanks": [26,[4],[5]],
		"/(web)/top-services-classes": [27,[4],[5]],
		"/(web)/tutors/[tutor]": [~28,[4],[5]],
		"/(web)/zoom": [29,[4],[5]],
		"/(web)/[service]": [~17,[4],[5]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';